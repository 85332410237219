header {
	&.banner {
		
		.navbar-collapse {
			padding-right:0px;
			padding-left:0px;	
		}
		
		ul {
			&.infos {
			padding-left:5px;
			text-align:center;
			list-style-type:none;
			
				@media (min-width: @screen-sm-min) {
					text-align:left;
					list-style-type:circle;
				}
				
			}
		}
		
		h1 {
			font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
			color:#000;
			font-size:0.6em;	
			text-align:center;
			margin:25px 0px;
			
			@media (min-width: @screen-sm-min) {
			 	font-size:0.9em;
			}
				
		}
		
		h2 {
			font-size:1.2em;
			color:black;
			font-style:italic;
			font-family: 'Playfair Display', serif;
			font-weight:700;	
			
			@media (min-width: @screen-sm-min) {
				color:black;
			}
		}
		
		#topNav {
			
			height:286px;
			
			&::before {
				content: '';
				position: absolute;
				width: 100%;
				height: 30%;
				top: 0;
				left: 0;
				opacity: 1;
				pointer-events: none;
				background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
			}
			
				@media (min-width: @screen-xs-min) {
					
				}
				@media (min-width: @screen-sm-min) {
			 		height:296px;
					
				   &::before {
					   background:none;
				   }
				}
				@media (min-width: @screen-md-min) {
				   height:326px;
				}
				@media (min-width: @screen-lg-min) {
				 
					height:356px;
				}
			
			.affix {
			
				
				margin-left:0px;
				margin-right:0px;
				
				top:0px;
				left:0px;
				width:100%;
				z-index:999;
				text-align:center;
				
				nav {
					/*background-color:rgba(red(@brand-primary), green(@brand-primary),blue(@brand-primary), 0.8);*/
					
					background: linear-gradient(to bottom, rgba(red(@brand-primary),green(@brand-primary),blue(@brand-primary),1) 45%, rgba(red(@brand-primary),green(@brand-primary),blue(@brand-primary),0) 100%);
					
					text-align:center;
					
					&:before  {
						height:50px;
						color:white;
						font-family: 'icomoon';
						content: "\e607";
						display:block;
						font-size:2em;
					}
					
					.account-buton {
						position:absolute;
						top:15px;
						right:25%;	
						display:inline-block;
						margin-top:0px;
					}
					
					.navbar-nav {
						display:inline-block;
						float:none;
						margin-bottom:-9px
					}
					
					li {
						a {
							padding:0.6em;
							font-size:1.4em;
						}
						&.active {
								a:first-child {
									border-bottom:7px solid @brand-primary;
								}
							}
					}
				}
			}
		}
		
	}
}

.account-buton {
	display: none;
}