footer {
	padding:50px 0px;
	
	h2 {
		color:black;
		font-weight:700;
		font-style:italic;
		font-family: 'Playfair Display', serif;	
	}
	
	a {
		&:hover {
			
		}	
	}
	
	.social {
		a {
			&:hover {
				text-decoration:none; 
			}
		}
	}
	
}