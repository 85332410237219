@font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon.eot?-yupiy7');
	src:url('../fonts/icomoon.eot?#iefix-yupiy7') format('embedded-opentype'),
		url('../fonts/icomoon.woff?-yupiy7') format('woff'),
		url('../fonts/icomoon.ttf?-yupiy7') format('truetype'),
		url('../fonts/icomoon.svg?-yupiy7#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"],[class^="glyphicon"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
	&.x2 {
		font-size:2em;	
		vertical-align:text-top;
	}
	
}

.icon-logo:before {
	content: "\e607";
}
.icon-cle:before {
	content: "\e600";
}
.icon-map:before {
	content: "\e602";
}
.icon-plat:before {
	content: "\e603";
}
.icon-poisson:before {
	content: "\e604";
}
.icon-facebook-with-circle:before {
	content: "\e605";
}
.icon-twitter-with-circle:before {
	content: "\e606";
}
.icon-home2:before {
	content: "\e601";
}
.icon-drop2:before {
	content: "\e635";
}
.icon-snow2:before {
	content: "\e637";
}
.icon-sun:before {
	content: "\e647";
}
.icon-lock:before {
	content: "\e66b";
}
.icon-heart:before {
	content: "\e68c";
}
.icon-star:before {
	content: "\e68d";
}
.icon-printer:before {
	content: "\e6b1";
}
.icon-papers:before {
	content: "\e6d4";
}
.icon-medal-first:before {
	content: "\e6de";
}
.icon-picture2:before {
	content: "\e70f";
}
.icon-profile:before {
	content: "\e71b";
}
.icon-user:before {
	content: "\e71e";
}
.icon-bag:before {
	content: "\e74b";
}
.icon-cart:before {
	content: "\e74d";
}
.icon-cart-add:before {
	content: "\e752";
}
.icon-credit-card:before {
	content: "\e759";
}
.icon-cash-euro:before {
	content: "\e75b";
}
.icon-coin-euro:before {
	content: "\e763";
}
.icon-compass2:before {
	content: "\e780";
}
.icon-map2:before {
	content: "\e782";
}
.icon-bubbles:before {
	content: "\e7d7";
}
.icon-glass:before {
	content: "\e80d";
}
.icon-leaf:before {
	content: "\e82d";
}
.icon-tree:before {
	content: "\e830";
}
.icon-truck:before {
	content: "\e857";
}
.icon-ping-pong:before {
	content: "\e869";
}
.icon-antenna:before {
	content: "\e8c8";
}
.icon-cross-circle:before {
	content: "\e95a";
}
.icon-plus-circle:before {
	content: "\e95b";
}
.icon-chevron-left-circle:before {
	content: "\e964";
}
.icon-chevron-right-circle:before {
	content: "\e965";
}

.glyphicon {
	&.glyphicon-chevron-left {
		&:extend(.icon-chevron-left-circle all);
	}
	
	&.glyphicon-chevron-right {
		&:extend(.icon-chevron-right-circle all);
	}
}

h1, .h1 {
	font-size: 30px;
	
	 @media (min-width: @screen-xs-min) {
	}
	 @media (min-width: @screen-sm-min) {
	 font-size: 24px;
	}
	 @media (min-width: @screen-md-min) {
	 font-size: 20px;
	}
	 @media (min-width: @screen-lg-min) {
		 font-size: 26px;
	}
}

h2, .h2 {
	font-size: 30px;
	
	 @media (min-width: @screen-xs-min) {
	}
	 @media (min-width: @screen-sm-min) {
	 font-size: 20px;
	}
	 @media (min-width: @screen-md-min) {
	 font-size: 22px;
	}
	 @media (min-width: @screen-lg-min) {
		 font-size: 30px;
	}
}

h3, .h3 {
	
	font-size: 20px;
	
	 @media (min-width: @screen-xs-min) {
	}
	 @media (min-width: @screen-sm-min) {
	 font-size: 18px;
	}
	 @media (min-width: @screen-md-min) {
	 font-size: 18px;
	}
	 @media (min-width: @screen-lg-min) {
		 font-size: 25px;
	}
}

h4, .h4 {
	font-size: 18px;
	
	 @media (min-width: @screen-xs-min) {
	}
	 @media (min-width: @screen-sm-min) {
	 font-size: 14px;
	}
	 @media (min-width: @screen-md-min) {
	 font-size: 15px;
	}
	 @media (min-width: @screen-lg-min) {
		 font-size: 18px;
	}
}

.btn {
	font-size: 14px;
	
	 @media (min-width: @screen-xs-min) {
	}
	 @media (min-width: @screen-sm-min) {
	 font-size: 11px;
	}
	 @media (min-width: @screen-md-min) {
	 font-size: 12px;
	}
	 @media (min-width: @screen-lg-min) {
		 font-size: 14px;
	}
}
