// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:less
@import "../../bower_components/bootstrap/less/bootstrap.less";
// endbower

@import "common/_variables";
@import "common/_global";
@import "components/_buttons";
@import "components/_comments";
@import "components/_forms";
@import "components/_grid";
@import "components/_wp-classes";
@import "layouts/_header";
@import "layouts/_sidebar";
@import "layouts/_footer";
@import "layouts/_pages";
@import "layouts/_posts";

@import (inline) "../../bower_components/blueimp-gallery/css/blueimp-gallery.css";
@import (inline) "../../bower_components/animate.css/animate.min.css";


@import "components/_gallery";         // Lightbox

@import url(//fonts.googleapis.com/css?family=Pacifico);
@import url(//fonts.googleapis.com/css?family=Playfair+Display:400,900,400italic,900italic);

textarea { resize: vertical; }

.btn { white-space: normal; }

body {
	background-position:50% 0px;
	background-repeat:no-repeat;
	background-size:auto 10%;
	
	@media (min-width: @screen-sm-min) {
		background-position:50% 150px;
		background-size:auto 20%;
	}	
	
	@media (min-width: @screen-md-min) {
		background-position:50% 170px;
		//background-size:auto 28%;
		background-size:auto 35%;
	}
}

a, img, nav, .nav {
	
	transition: all 0.4s ease;	
}


.acf-map {
	height: 400px;
	border: #ccc solid 1px;
	margin: -35px -35px 35px -35px;
}

.main {
	padding-right:0px;
	padding-left:0px;	
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 , h5, .h5, h6, .h6 {
margin-top: 0px;
}

h1, .h1 { font-weight:900; 

	font-family:'Pacifico', cursive;
	color:@brand-primary;
	
	&.big {
		padding:0.25em 0.5em;
		text-align:center;
		display:inline-block;
		margin:0 auto;
	}
	
	&.red {
		background-color:@brand-primary;
		color:white;
		
	}
	
	&.black {
		background-color:@brand-secondary;
		color:white;

	}
	
	&.white {
		background-color:none;
		color:@brand-primary;

	}

}
h2, .h2 {
	
	font-family:'Pacifico', cursive;
	color:@brand-primary;
	
	&.big {
		padding:0.25em 0.5em;
		text-align:center;
		display:inline-block;
		margin:0 auto;
	}
	
	&.prune {
		background-color:@brand-primary;
		color:white;
		
	}
	
	&.black {
		background-color:@brand-secondary;
		color:white;

	}
	
	&.white {
		background-color:none;
		color:@brand-primary;

	}
}

h3, .h3 { 

	font-family: 'Playfair Display', serif;
	font-style: italic;
	
	
	strong {
		font-weight:700;
	}
	
	&.big {
		padding:0.25em 0.5em;
		text-align:center;
		display:inline-block;
		margin:0 auto;
	}
	
	&.red {
		background-color:@brand-primary;
		color:white;
		
	}
	
	&.black {
		background-color:@brand-secondary;
		color:white;

	}
	
	&.white {
		background-color:none;
		color:@brand-primary;

	}
	
}
h4, .h4, h5, .h5, h6, .h6 { font-weight:300; }
.container { position:relative; }

/* nav */

#menu-menu-1 {
	
}

nav, .nav {
	
	ul:not(.dropdown) {

		display: flex;
		float: none;
		
		&:last-child {
				/* rules for mobile vertical (< 480) */
				@media (min-width: @screen-xs-min) {
					
				}
				@media (min-width: @screen-sm-min) {
			 
				   
				}
				@media (min-width: @screen-md-min) {
				     margin-left:10px; 
				}
				@media (min-width: @screen-lg-min) {
				 
				 margin-left:8px; 
				}
		}
				
	
	}
	
	li {

		flex: 1;
		
		a {
		font-family: "Pacifico", script;
		
		text-transform:capitalize;
		background-color:transparent;
		color:@brand-primary;
		font-size:1.4em;
		//padding:1.5em 1.3em;
		text-align: center;
		
			&:hover, &:focus {
				color:lighten(@brand-primary,25%);
			}
			
			/* rules for mobile vertical (< 480) */
			@media (min-width: @screen-xs-min) {
				
			}
			@media (min-width: @screen-sm-min) {
			 
			  //padding:1.5em 0.5em;
			  font-size:1.3em;
			}
			@media (min-width: @screen-md-min) {
			//padding:1.5em 1.33em;
			}
			@media (min-width: @screen-lg-min) {
			  //padding:1.5em 1.08em;
			  font-size:1.4em;
			}
		}
		
		&:nth-child(odd)  {
			background-color:#fbefab;
		}
		
		&:nth-child(even)  {
			background-color:#faf1c0;
		}
		
		.icon-menu {
			display:block;
			font-size:3em;
			text-align:center;
		}
		
		&.active {
			a:first-child {
				background-color:@brand-secondary;
				border-bottom:7px solid @brand-primary;
			}
		}
	}
	
	.account-buton {
			
	}
	
	.open {
		a, a:focus, a:hover {
		background-color:transparent;
		border:none;
		color:white;
		}
	}
	
	.dropdown-menu {
		background-color:rgba(red(@brand-secondary),green(@brand-secondary),blue(@brand-secondary),0.9);
		border-radius:10px;
		
		&:after {
			bottom: 100%;
			left: 30%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(red(@brand-secondary), green(@brand-secondary), blue(@brand-secondary), 0);
			border-bottom-color: @brand-secondary;
			border-width: 10px;
			margin-left: -10px;
		}
		
		
		
			li {
				
				a {
					color:@brand-secondary;
					border-radius:0px;
					background-color:#fff;
					border-top:1px solid lighten(@brand-secondary,45%);
					
					.icon-menu {
						&:extend(.icon-circle-plus all);
						display:inline;
						font-size:0.8em;
					}
					&:hover, &:focus {
					color:lighten(@brand-secondary,25%);
					text-decoration:none;
					}
					
					
				}
				
				&.active {
						a {
						#gradient > .vertical(#ffffff, lighten(@brand-secondary,40%));	
						}
					}
				
				&:hover, &:focus {
				
				}
			}
		
		
			
	}
	

}


/* offcanvas menu*/
.navmenu {
	
	width:280px;	
	right:-280px;
	overflow-x:hidden;
	border-top-left-radius:0px;
	border-top-right-radius:0px;
	
	border-radius:0;
	border-width:0px;
	border-left:none;
	
	.panel {
		border:none;
		border-radius:0;
		background-color:transparent;
		
		.panel-body {
			padding:20px 0px;	
		}
	}
	
	.navbar-nav {
		margin:0px;	
	}
	
	.nav {
	
		&:after {
			display:none;	
		}
		
		li {
			
			border-bottom:none;
			
			
			a {
				font-family:'Pacifico', cursive;
				font-weight:700;
				text-transform:none;
				border-radius:0px;
				color:@brand-primary;
				font-size:1.15em;
				padding: 0.75em 1.3em;
				
				&:hover, &:focus {
				color:inherit;	
				}
				
				.icon-menu {
					display:inline;
					font-size:1.6em;
				}
			}
		}
		
		.dropdown-menu {
			
			padding:0px;
			
				&:after {
					display:none;	
				}
				
				a {
					border-radius:0px;
				
				}
			}
		
	}
}

.navbar-toggle {
	position:absolute;
	right:1em; 
	z-index:2;
	background-color: @brand-primary;

	.icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
  background-color: white;
	}
}

@media (min-width: @screen-sm-min) {
	ul.nav li.dropdown > ul.dropdown-menu{
	opacity:0;
	visibility:hidden;
	display:block;
	margin-top:-25px;
	transition: opacity 0.5s ease-in-out, margin-top 0.2s ease-in-out;
	}
	ul.nav li.dropdown:hover > ul.dropdown-menu{
	visibility:visible;
	opacity:1;
	margin-top:-5px;
	display: block;
	}
} 
.rsImg {
	margin-top:0px !important;	
}

.nav > li > a:hover {  background-color:transparent;	 }

@media (min-width: @screen-sm-min) {
ul.nav li.dropdown > ul.dropdown-menu{
opacity:0;
visibility:hidden;
display:block;
margin-top:-25px;
-webkit-transition: opacity 0.5s ease-in-out, margin-top 0.2s ease-in-out;
-moz-transition: opacity 0.5s ease-in-out, margin-top 0.2s ease-in-out;
-o-transition: opacity 0.5s ease-in-out, margin-top 0.2s ease-in-out;
transition: opacity 0.5s ease-in-out, margin-top 0.2s ease-in-out;
}
ul.nav li.dropdown:hover > ul.dropdown-menu{
visibility:visible;
opacity:1;
margin-top:-5px;
display: block;
}
 } 

.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}

.carousel {
	
	margin-top:60px;
	
	@media (min-width: @screen-sm-min) {
		margin-top:40px;
	}
	
	border-radius : 5px;
	background-color:none;
	
	.carousel-control.right, .carousel-control.left {
		background-image:none;
	}
	
	.carousel-caption {
		text-align:left;
		position:relative;
		text-shadow:none;	
	}
	
	.carousel-indicators {
		position: absolute;
		top: -40px;
		left: 0px;
		height:50px;
		z-index: 15;
		width: 60%;
		margin-left: 0%;
		padding-left: 0;
		list-style: none;
		text-align: left;
		
			li  {
				width:20px;
				height:20px;
				margin-right:0.5em;
				background-color:@brand-primary;
				border:none;
				
				&.active {
					background-color:@brand-secondary;
				}
			}
		
		}
	
}
/*
  Bootstrap Carousel Fade Transition (for Bootstrap 3.3.x)
  CSS from:       http://codepen.io/transportedman/pen/NPWRGq
  and:            http://stackoverflow.com/questions/18548731/bootstrap-3-carousel-fading-to-new-slide-instead-of-sliding-to-new-slide
  Inspired from:  http://codepen.io/Rowno/pen/Afykb 
*/

.carousel-inner {
			img {
				&:extend(.img-responsive);
				
			border-left:5px solid @brand-primary;	
		
			}
		}

.carousel-control.right, .carousel-control.left {
	background-image:none;	
}

.carousel-control .glyphicon-chevron-right {
right: 25%;
}

.carousel-control .glyphicon-chevron-left {
left: 25%;
}

.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
  opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.carousel-fade .carousel-control {
  z-index: 2;
}

/*
  WHAT IS NEW IN 3.3: "Added transforms to improve carousel performance in modern browsers."
  Need to override the 3.3 new styles for modern browsers & apply opacity
*/
@media all and (transform-3d), (-webkit-transform-3d) {
    .carousel-fade .carousel-inner > .item.next,
    .carousel-fade .carousel-inner > .item.active.right {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.prev,
    .carousel-fade .carousel-inner > .item.active.left {
      opacity: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    .carousel-fade .carousel-inner > .item.next.left,
    .carousel-fade .carousel-inner > .item.prev.right,
    .carousel-fade .carousel-inner > .item.active {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
}

img.line {
	border-left: 5px solid @brand-primary;	
}

.img-responsive {
	width:100%;
	
	&.wp-image-116 {
		width:auto;
	}
}
.attachment-thumbnail {
	&:extend(.img-responsive);	
}


.staggered-animation {
  opacity: 0;
}
 
.staggered-animation.animated {
    opacity: 1;
}

.btn-secondary {
	background:@brand-secondary;	
	
	h3, h4 {
		text-shadow: 2px 2px #fff;	
	}
	
}

.panel {
	&.details {
		
		background-color:transparent;
		border:none;
		
		.panel-heading {
			background: @brand-secondary;
			text-align:center;
			text-shadow: 2px 2px #fff;	
			border:none;
			border-radius:0px;
			margin-bottom:0px;
			&:extend(h2);
			padding:20px 10px;
		}
		
		.panel-body {
			background: rgba(red(@brand-primary),green(@brand-primary),blue(@brand-primary),0.5);
			color:white;
		}
	}
	
	&.panel-info {
		background-color:@brand-secondary;
		border:none;
		
		h4 {
			color:@brand-primary;
			text-shadow: 2px 2px #fff;
		}
	}
}

blockquote {
  border-left: 5px solid @brand-primary;
}

.logo {
			filter: drop-shadow( 4px 4px 0px #fff );
			margin-bottom:25px;
		}
		
		h3.light {
				font-family:'Pacifico', cursive;
				color:lighten(@brand-secondary,25%);
				text-shadow: 2px 2px @brand-primary;
				font-size:2em;		
			}
			
#cookie-banner {
	z-index:100;
	width:100%;
	background:@brand-primary;
	position:fixed;
	opacity:0.9;
	padding:1.5em;
	bottom:0px;
	font-size:11px;
	text-align:center;
	
}


.gallery-item {
	width:auto !important;
	
	}
	
	.size-auto, 
	.size-full,
	.size-large,
	.size-medium,
	.size-thumbnail {
		max-width: 100%;
		height: auto;
	}
	
	.jp-carousel-wrap {
		
		position: fixed !important;
		overflow: hidden;
		
		.jp-carousel-overlay {
			
			position: fixed !important;
			overflow: hidden;
			
		}
		
		.jp-carousel-image-meta {
		display: none;
		}
		
		#jp-carousel-comments-loading, .jp-carousel-fadeaway {
			display: none !important;
		}
	}