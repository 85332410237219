.home {
	
	.wrap {
		
		h2 {
			
			font-size:3em;
			
		}
		
	}
	
	
	header {
		h2 {
			color:white;	
			
			@media (min-width: @screen-sm-min) {
				color:black;
			}
		}
		.banner {
			 #topNav {
				height:226px; 
			 }
		}
	}
	
	section {
	
		height: auto;  
		margin: 0 auto; 
		width: 100%; 
		position: relative; 
		
	
		.overlay {
			position:absolute;
			width:100%;
			height:100%;
			top:0px;
		}
	}
	#top {
		
		padding: 80px 0 30px 0;
		min-height:320px;
		
		&::before {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				opacity: 1;
				pointer-events: none;
				background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(137,223,255,0) 80%, rgba(255,255,255,1) 100%);
			}
		
			@media (min-width: @screen-sm-min) {
				padding: 50px 0;
				   //min-height:680px;
				   
				}
				@media (min-width: @screen-md-min) {
				   &::before {
					   background:none;
				   }
				}
				@media (min-width: @screen-lg-min) {
				 
				}
		
		.overlay {
			
		}
		
		h3 { }
		
		.infos {
			
			padding-top:4%; 
			 
			@media (min-width: @screen-xs-min) {
					
				}
				@media (min-width: @screen-sm-min) {
			 
				   
				}
				@media (min-width: @screen-md-min) {
				    padding-top:4%;  
				}
				@media (min-width: @screen-lg-min) {
				 
				 
				}	
		}
		
		
			
	}
	
	#produits {
		
		display: none;

		background-size: cover;
		background-repeat:no-repeat;
		
		min-height:760px;
		background-color:#fff;	
		
		padding: 50px 0;
		
		#degustation {
			
			
			@media (min-width: @screen-md-min) {
							 
					position:absolute;
					bottom:0px;
					right:0px;
					width:25%;
					text-align:center;
					
				}
			
			a {
				
				font-family: 'Playfair Display', serif;
				font-style: italic;
				font-weight:bold;
				
				span {
					color:black;
					text-decoration:underline;	
				}
			}
			
		}
		
		h3 { margin:1em 0em; }
		
		.illu {
			
			height:250px;
			
			@media (min-width: @screen-md-min) {
				    height:auto;  
				}
			
		}
		
		#carousel-prod {
			margin-top:50px;	
		}
		
		.infos {
			
			padding-top:0; 
			z-index:1;
			 
			@media (min-width: @screen-xs-min) {
					
				}
				@media (min-width: @screen-sm-min) {
			 	
				z-index:auto;
				   
				}
				@media (min-width: @screen-md-min) {
				 
				}
				@media (min-width: @screen-lg-min) {
				 
				}	
		}
		
		&::before {
				content: '';
				position: absolute;
				width: 100%;
				height: 101%;
				top: 0;
				left: 0;
				opacity: 1;
				pointer-events: none;
				background: linear-gradient(to bottom, rgba(255,255,255,0.8) 0%, rgba(137,223,255,0) 40%, rgba(137,223,255,0) 60%, rgba(255,255,255,0.8) 100%);
			}
			
		.woocommerce {
			.featured {
				.price {
					font-weight:bold;
					&:extend(.btn);
					&:extend(.btn-primary);
				}
			}
		}
	}
	
	#hebergements {
		
		min-height:800px;
		
		background-size: cover;
		background-repeat:no-repeat;
		
		padding: 50px 0;	
		
		.panel.details {
			margin-top:30px;	
		}
		
		.overlay {
			background-color:rgba(26,34,37,0.5);	
		}
		
		h3 { color:black; margin:1em 0em; }
		
		&::before {
				content: '';
				position: absolute;
				width: 100%;
				height: 101%;
				top: 0;
				left: 0;
				opacity: 1;
				pointer-events: none;
				background: linear-gradient(to bottom, rgba(255,255,255,0.8) 0%, rgba(137,223,255,0) 50%, rgba(137,223,255,0) 50%, rgba(255,255,255,0.8) 100%);
			}
		
	}
	
	.infos {
		h3 {
			
			font-size:2em;
			
			@media (min-width: @screen-xs-min) {
					
				}
				@media (min-width: @screen-sm-min) {
			 
				   
				}
				@media (min-width: @screen-md-min) {
				   
				}
				@media (min-width: @screen-lg-min) {
				 
				 
				}	
			
		}
	}
	
	.panel-actus {
		
		background-color:rgba(255,255,255,0.8);
		
		.actus {
			h3 {
				a  {
					color:black;
					font-size:1.25em;	
					text-decoration:none;
					font-weight:700;
					
					&:hover {
					text-decoration:underline;	
					}
				}
			}
			
			img {
				border:1px solid lighten(@brand-primary, 50%);	
				border-radius:5px;
			}
			
			.btn {
				margin-top:1em;	
			}
			
			p { color:black; }
			
		}
	}
		
}

.page-template, .single-post, .hebergements {
	section {
	
		height: auto;  
		margin: 0 auto; 
		width: 100%; 
		position: relative; 
		
	
		.overlay {
			position:absolute;
			width:100%;
			height:100%;
			top:0px;
		}
	}
	
	.main {
		min-height:780px;	
	}
	
	.page-header {
		background-size: cover;
		background-repeat:no-repeat;
		height:600px;
		
		h1 { 
		
		padding-top:33px;
		font-size:3em;
		text-shadow: 2px 2px #fff;
		text-align:center;
		
			@media (min-width: @screen-xs-min) {
					text-align:inherit;
				}
				@media (min-width: @screen-sm-min) {
			 
				   
				}
				@media (min-width: @screen-md-min) {
				   
				}
				@media (min-width: @screen-lg-min) {
				 
				 
				}	
		}
		
		&::before {
				content: '';
				position: absolute;
				width: 100%;
				height: 101%;
				top: 0;
				left: 0;
				opacity: 1;
				pointer-events: none;
				background: linear-gradient(to bottom, rgba(255,255,255,0.8) 0%, rgba(137,223,255,0) 50%, rgba(137,223,255,0) 50%, rgba(255,255,255,0.8) 100%);
			}
	}
	
	.page-content {
		  margin-top: -500px;
		  background: linear-gradient(to bottom, rgba(255,255,255,0.8) 0px, rgba(255,255,255,1) 300px);
		  min-height: 550px;
		border:1px solid #E0E0E0;
		padding:33px;
	}
	
	footer {
		padding:0px;
		padding-top:25px;
		padding-bottom:50px;
	}
}


.blog {
	
	article {
		img {
			padding-left:0px;
			border-left:5px solid @brand-primary;
		}
		margin-bottom:25px;
	}
	
	.page-header {
		background-size: cover;
		background-repeat:no-repeat;
		height:600px;
		
		margin:0px;
		
		h1 { 
		
		padding-top:33px;
		font-size:3em;
		text-shadow: 2px 2px #fff;
		text-align:center;
		
			@media (min-width: @screen-xs-min) {
					text-align:inherit;
				}
				@media (min-width: @screen-sm-min) {
			 
				   
				}
				@media (min-width: @screen-md-min) {
				   
				}
				@media (min-width: @screen-lg-min) {
				 
				 
				}	
		}
		
		&::before {
				content: '';
				position: absolute;
				width: 100%;
				height: 101%;
				top: 0;
				left: 0;
				opacity: 1;
				pointer-events: none;
				background: linear-gradient(to bottom, rgba(255,255,255,0.8) 0%, rgba(137,223,255,0) 50%, rgba(137,223,255,0) 50%, rgba(255,255,255,0.8) 100%);
			}
	}
	
	.page-content {
		  margin-top: -500px;
		  background: linear-gradient(to bottom, rgba(255,255,255,0.8) 0px, rgba(255,255,255,1) 300px);
		  min-height: 550px;
		border:1px solid #E0E0E0;
		padding:33px;
	}
}

.single {
	.wp-post-image {
		border-left:5px solid @brand-primary;
	}
	main {
		header {
		padding:25px 0px;
		}
	}
}

.hebergements {
	.page-header {
		
	}
}

.nos-produits {
	figure {
		
		img {
			position:relative;
			opacity:1;
			
			&:hover {
				position:relative;
				opacity:0.8;	
			}
		}
	}
	figcaption {
		display:none;	
	}
}

.gite {
			background-color:@brand-secondary;
			border-radius:5px;
			
			@media (min-width: @screen-md-min) {
				 margin:45px 0px;
				}
			
			
			p { 
				padding:5px 15px; 
				font-size:1em;
				text-align:center;
			}
			
			.btn {
				background-color:lighten(@brand-secondary, 25%);
				font-weight:bold;	
			}
			
			h3 {
				padding-top:1em;
				font-weight:700;
				text-shadow: 2px 2px #fff;	
			}
			
			img {
				border:3px solid white;
				
				backface-visibility: hidden;
				transform:translate3d(0,0,0);
				
				&:extend(.img-responsive);
				
				&:hover {
					opacity:0.85;
					transform:scale(1.1);
					border:5px solid white;
					box-shadow: 0px 0px 5px 0px darken(@brand-primary, 15%);
				}
			}
			
		}
		
		
/* COMMERCE */ 
.woocommerce {
	
	.cart-collaterals {
		.cart_totals {
			table {
				width:100%;
			}
		}
	}
	
	#shipping_method {
		label {
			display: inline;
		}
	}
	
	.wrap {
		.content {
			margin-top:30px;	
		}
	}
	
	.sidebar {
		
	 >.panel {
		 display:block !important;
	 }
	 
	 .widget_shopping_cart {
		 h3 {
			 
			&:extend(.icon-bag all);
			 
		 	&:before {
			 	
					font-family: 'icomoon';
					speak: none;
					font-style: normal;
					font-weight: normal;
					font-variant: normal;
					text-transform: none;
					line-height: 1;
				
					/* Better Font Rendering =========== */
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
				
			}
		 }
	 }
	 
	}
	
	.page-header {
		background-size: cover;
		background-repeat:no-repeat;
		height:600px;
		 background: url('/wp-content/uploads/2015/05/canards-elevage.jpg') 50% 0px no-repeat;
	}
	
	.page-content {
		  margin-top: -500px;
		  background: linear-gradient(to bottom, rgba(255,255,255,0.8) 0px, rgba(255,255,255,1) 300px);
		  min-height: 550px;
		border:1px solid #E0E0E0;
		padding:33px;
	}
	
	input[type="text"], input[type="password"], input[type="email"], input[type="search"] {
		&:extend(.form-control);	
	}
	
	ul {
		&.products {
			li {
				&.product {
					a {
						img {
							border-left:5px solid @brand-primary;
						}
					}
				}
			}
		}
	}
}

.woocommerce-account {
	
	.address {
		&:extend(.panel);	
		&:extend(.panel-default);
		padding:2em;
		
		a {
			&.edit {
				&:extend(.btn);	
				&:extend(.btn-xs);	
				&:extend(.btn-primary);	
			}
		}
	}
}